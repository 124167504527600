<template>
  <form @submit.prevent="login" class="container">
    <div>
      <router-link to="/ask-auth">
        <img src="@/assets/Chevron.png" />
      </router-link>
    </div>
    <div>
      <p class="heading">Log in</p>
    </div>
    <div>
      <input type="email" class="input" v-model="email" placeholder="Email" />
    </div>
    <div>
      <input type="password" class="input" placeholder="Password" v-model="password" />
    </div>
    <p v-if="loginError && !isLoading">{{ loginError }}</p>
    <p v-if="isLoading">loading...</p>

    <div>
      <button type="submit" class="loginButton">LOG IN</button>
    </div>
    <div>
      Forget password?
      <router-link to="/reset">Reset here.</router-link>
    </div>
  </form>

</template>

<script>
import { mapState, mapMutations } from "vuex";
import { isNil } from "lodash";
import firebase from "firebase/app";
import { desktop as isDekstop } from "is_js";

export default {
  data: () => ({
    loginError: null,
    email: "",
    password: "",
    isLoading: false,
    policy: false
  }),
  head() {
    return {
      title: {
        inner: "Login"
      },
      meta: [
        {
          name: "description",
          content: `Sign in or sign up to ${this.appTitle}`,
          id: "desc"
        }
      ]
    };
  },
  computed: {
    ...mapState("authentication", ["user"]),
    ...mapState("app", ["networkOnLine", "appTitle"])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? user.type == "expert"
              ? "/expert-queries"
              : "/queries"
            : this.$route.query.redirectUrl;
          this.$router.push(redirectUrl);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations("authentication", ["setUser"]),
    async login() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.loginError = null;
      const db = firebase.firestore();
      try {
        // Firebase signin with popup is faster than redirect
        // but we can't use it on mobile because it's not well supported
        // when app is running as standalone on ios & android
        // eslint-disable-next-line no-unused-expressions
        const user = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        this.$store.dispatch(
          `authentication/login`,
          firebase.auth().currentUser
        );
      } catch (err) {
        console.error(err);
        this.loginError = err;
        this.setUser(null);
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.container {
  margin: 20px;
  // margin-top: 20px;

  .heading {
    font-family: $header-title;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
  }

  .input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-style: solid;
    font-size: 18px;
  }

  .loginButton {
    margin-top: 10px;
    width: 100%;
    padding: 18px 5px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
  }
}
</style>
